@tailwind base;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 198, 85%, 47%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 0, 0%, 87%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    /* text color */
    --dark: 0, 0%, 14%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  /* taken from tailwind preflight/reset css  */
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
  }

  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  button {
    cursor: pointer;
  }

  @media (max-width: 480px) {
    .adyen-checkout__payment-method__brands {
      @apply flex-nowrap;
    }

    .adyen-checkout__payment-method__header
      .adyen-checkout__payment-method__image__wrapper--outline {
      @apply !w-10 flex items-center justify-center [&_img]:!transform-none;
    }

    .adyen-checkout__payment-method__brands
      .adyen-checkout__payment-method__image__wrapper--outline:nth-of-type(
        odd
      ) {
      @apply hidden;
    }
  }
}

@tailwind components;
@tailwind utilities;

.PromotionPlan {
  opacity: 1;
  transform: scale(1);
  transition:
    transform 500ms ease-in-out,
    opacity 500ms ease-in-out;

  @starting-style {
    opacity: 0;
    transform: scale(0);
  }
}

@layer components {
  /* MINDFULNESS TEXT SECTION */

  .TextSection--ElementInnerContent a {
    @apply text-primary font-medium;
  }

  .TextSection--ElementInnerContent p:first-of-type,
  .TextSection--ElementInnerContent ul:first-of-type {
    margin-top: 0;
  }

  /* ==== */

  .TextSection--ElementContent {
    h3 {
      @apply font-medium text-xl mb-2 mt-0;
    }

    a {
      @apply text-primary;
    }

    li {
      @apply mb-3;
    }
  }

  .TextSection--BorderElement {
    position: relative;
    border: 1px solid black;
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 30px 35px;
  }

  .TextSection--BorderElement .TextSection--ElementContent {
    position: relative;
    z-index: 2;
  }

  .TextSection--BorderElement:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    display: block;
    width: 80px;
    height: 80px;
    z-index: 0;

    @apply bg-neutral-50;
  }

  .TextSection--BorderElement:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    display: block;
    width: 80px;
    height: 80px;
    z-index: 0;

    @apply bg-neutral-50;
  }

  /* MINDFULNESS TEXT SECTION */

  /*  */
  .Section--TwoColumn {
    h1,
    h2,
    h3 {
      @apply m-0 mb-2;
    }
  }
  /*  */

  /* Blog post */
  .Blog--Content {
    @apply font-light text-base;
  }

  .Blog--Content h1,
  .Blog--Content h2,
  .Blog--Content h3,
  .Blog--Content h4,
  .Blog--Content h5,
  .Blog--Content h6,
  .Blog--Content b {
    @apply font-normal;
  }

  .Blog--Content h1 {
    @apply text-xl;
  }

  .Blog--Content h2 {
    @apply text-lg;
  }
  .Blog--Content a {
    @apply text-primary;
  }

  .Blog--Content ul {
    @apply ml-4 p-0;
  }

  .video-thumbnail--inline__container {
    @apply inline-block w-full lg:w-[45%] my-2.5 lg:mx-2 border border-neutral-200 rounded align-middle;
  }

  .video-thumbnail--inline__container .video-thumbnail__image__aspect-ratio {
    @apply relative flex;
  }

  .video-thumbnail--inline__container .video-top-left-wrapper {
    @apply absolute top-1 left-1 text-xs py-1 px-2 rounded bg-neutral-800 text-white;
  }

  .video-thumbnail--inline__container .video-bottom-right-wrapper {
    @apply absolute bottom-2.5 right-1 text-xs py-1 px-2 rounded bg-neutral-800 text-white;
  }

  .video-thumbnail--inline__container img {
    @apply w-full;
  }

  .video-thumbnail--inline__container .video-thumbnail__description__wrapper {
    @apply py-5 px-4;
  }

  .video-thumbnail--inline__container .video-thumbnail__title-text {
    @apply text-dark text-base font-semibold mt-0 mb-1;
  }

  .video-thumbnail--inline__container .video-thumbnail__subtitle p {
    @apply text-neutral-600 text-sm mt-0 mb-2;
  }
  .video-thumbnail--inline__container .video-thumbnail__description p {
    @apply text-neutral-600 text-sm m-0 line-clamp-2 h-10;
  }

  .Blog--VideosContainer {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-3 py-4;
  }

  /* Blog post end */

  /*  */

  .MarketPlaceEvent--Content {
    @apply font-light text-base;
  }

  .MarketPlaceEvent--Content h1,
  .MarketPlaceEvent--Content h2,
  .MarketPlaceEvent--Content h3,
  .MarketPlaceEvent--Content h4,
  .MarketPlaceEvent--Content h5,
  .MarketPlaceEvent--Content h6,
  .MarketPlaceEvent--Content b {
    @apply font-normal m-0;
  }

  .MarketPlaceEvent--Content h1 {
    @apply text-2xl;
  }

  .MarketPlaceEvent--Content h2 {
    @apply text-xl;
  }
  .MarketPlaceEvent--Content a {
    @apply text-primary;
  }

  .MarketPlaceEvent--Content ul {
    @apply ml-4 p-0;
  }
  /*  */
}
